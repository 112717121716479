import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";

function ProblemScapeCh4Page() {
  return (
    <>
      <ProblemScapeGameHeader />
      <section
        id="chapter-4-overview"
        className="Index-page"
        data-collection-id="6063b2379ef9be7472f486fd"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-content "
          id="yui_3_17_2_1_1671304180297_3283"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-6063b2379ef9be7472f486fd"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671304180297_3282">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671304180297_3281"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-971721c16ae66131ef64"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      CHAPTER 4 OVERVIEW:
                      <br />
                      INTO THE WOODS
                    </h2>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_3280"
                >
                  <div
                    className="col sqs-col-7 span-7"
                    id="yui_3_17_2_1_1671304180297_3279"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-b3ba5c35cb5d03834d2f"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3278"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_3277"
                        >
                          <StaticImage
                            src="../../images/problemscape/door.png"
                            alt=""
                            height={440}
                            objectFit="cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-5 span-5"
                    id="yui_3_17_2_1_1671304180297_3304"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="100.45558086560365"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1658168014982_43318"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3303"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_3302"
                        >
                          <StaticImage
                            src="../../images/standards/chapter-4.png"
                            alt="Chapter 4"
                            height={440}
                            objectFit="cover"
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "center",
                            }}
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-a9afcc8b9fba7a193267"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      <strong>Entering the Emerald Forest</strong>
                    </h3>
                    <ul data-rte-list="default">
                      <li>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          The player and Jinga follow the PiRat impostor and
                          reach the emerald forest, but need an emerald pendant
                          to enter the forest. The PiRat has disappeared into
                          the woods and they need to follow the clues to find
                          him. When they reach the caves, they need to fight a
                          paintball battle with not one, but three PiRats! Can
                          they win and rescue the kidnapped shopkeeper?
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_3324"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_3323"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="66.97936210131333"
                      data-block-type="5"
                      id="block-4aa83c43beab311cb6bb"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3322"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_3321"
                        >
                          <StaticImage
                            src="../../images/problemscape/compass.png"
                            alt=""
                            width={533}
                            height={357}
                            objectFit="cover"
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "center",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_3343"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-b9726f97b4a7a299fefd"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3342"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_3341"
                        >
                          <StaticImage
                            src="../../images/problemscape/mines.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Head() {
  return <Seo title="Ch 4 Overview" />;
}

export default ProblemScapeCh4Page;
